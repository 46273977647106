import React, { useLayoutEffect, useState, useEffect } from "react";
import styled from "styled-components";
import SocialIcons from "../subComponents/SocialIcons";
import { Intro1, Intro2 } from "./Intro";
import { pageAnimation } from "../animation";
import { motion } from "framer-motion";

const MainContainer = styled(motion.div)`
  background: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Karla", sans-serif;
    font-weight: 500;
  }
`;

const Container = styled.div`
  padding: 2rem;
`;

const DarkDiv = styled.div`
  position: absolute;
  top: 0;
  background-color: #000;
  bottom: 0;
  right: 50%;
  width: 50%;
  height: 100%;
  z-index: 1;
  transition: height 0.5s ease, width 1s ease 0.5s;
  @media (max-width: 800px) {
    height: 50%;
    right: 0px;
    width: 100%;
  }
`;

const Main = (props) => {
  useEffect(() => {
    if (window.innerWidth <= 800) {
      if (props.clicked === true) {
        props.setClicked(false);
      }
    }
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState(false);
    useLayoutEffect(() => {
      function updateSize() {
        if (window.innerWidth < 800) {
          // console.log("2");
          setSize(true);
        } else {
          setSize(false);
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  return (
    <MainContainer
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <DarkDiv />
      <Container>
        {!useWindowSize() ? (
          <SocialIcons theme={"dark"} />
        ) : (
          <SocialIcons theme={""} />
        )}
      </Container>
      {useWindowSize() ? <Intro2 /> : <Intro1 />}
    </MainContainer>
  );
};

export default Main;
