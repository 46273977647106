export const MenuList = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Certificates",
    url: "/certificates",
  },
  {
    title: "Work",
    url: "/work",
  },
  {
    title: "Skills",
    url: "/skills",
  },
  {
    title: "Contact Me",
    url: "/contactus",
  },
];
