import React, { useEffect } from "react";
import styled, { keyframes, ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";

import SocialIcons from "../subComponents/SocialIcons";
import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitlte";
import Cube from "../components/cube/Cube";

import { pageAnimation } from "../animation";
import { motion } from "framer-motion";
import astronaut from "../assets/Images/spaceman.png";

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;
const float = keyframes`
0% { transform: translateY(-10px) }
50% { transform: translateY(15px) translateX(15px) }
100% { transform: translateY(-10px) }

`;
const Spaceman = styled.div`
  position: absolute;
  top: 26%;
  right: 8%;
  width: 20vw;
  animation: ${float} 4s ease infinite;

  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 800px) {
    top: 5%;
    right: -5%;
    animation: none;
    -webkit-animation: none;
  }
`;
const Spaceman2 = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  width: 20vw;
  animation: ${float} 4s ease infinite;
  img {
    width: 100%;
    height: auto;
  }
`;
const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  width: 50vw;
  height: 60vh;
  z-index: 3;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: calc(0.6rem + 1vw);
  backdrop-filter: blur(4px);

  position: absolute;
  left: calc(5rem + 5vw);
  top: 10rem;
  font-family: "Ubuntu Mono", monospace;
  font-style: italic;
`;

const AboutPage = (props) => {
  useEffect(() => {
    if (window.innerWidth <= 800) {
      if (props.clicked === true) {
        props.setClicked(false);
      }
    }
  }, []);
  return (
    <ThemeProvider theme={DarkTheme}>
      <motion.div
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        <Box>
          <SocialIcons theme="dark" />
          <ParticleComponent theme="dark" />

          <Spaceman>
            {/* <img src={astronaut} alt="spaceman" /> */}
            <Cube />
          </Spaceman>

          <Main>
            Name - vidhi Vinaykumar patel
            <br />
            Age - 21 <br />
            Nationality - Indian
            <br />
            Qualification —{">"}
            <br /> - 2016-17 I have passed SSC board examination with 97.26 Pr
            <br /> - 2018-19 I have passed HSC board examination with 84.02 Pr
            <br />
            <br /> I love to create simple yet beautiful websites with great
            user experience.
            <br /> I'm interested in the Full stack development Like trying new
            things and building great projects.
          </Main>
          <BigTitle text="ABOUT" top="10%" left="5%" />
        </Box>
        <Box>
          <SocialIcons theme="dark" />
          <ParticleComponent theme="dark" />

          <Spaceman2>
            <img src={astronaut} alt="spaceman" />
            {/* <Cube /> */}
          </Spaceman2>

          <Main>
            I'm also intreseted in Building applications on AR/VR using Unity 3D
            and Vuforia Enginee. I'm also interested in Building IoT enabled
            Project.
            <br /> <br />
            I'm an aesthete who loves and appreciates works of art and crafts
            beautiful things. I'm a cinephile to whom a movie is not just a form
            of entertainment as they see films from a more critical point of
            view. I love to read books.
            <br /> I believe everything is an Art when you put your
            consciousness in it. You can connect with me via social links.
          </Main>

          <BigTitle text="ABOUT" top="10%" left="5%" />
        </Box>
      </motion.div>
    </ThemeProvider>
  );
};

export default AboutPage;
