import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MenuList } from "./MenuList";
import "./Navbar.css";

import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { motion } from "framer-motion";
import logo from "../../assets/Images/logo.png";
const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "100%" },
};

const Navbar = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 800);
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const menuList = MenuList.map(({ url, title }, index) => {
    return (
      <li key={index}>
        <NavLink exact to={url} activeClassName="active">
          {title}
        </NavLink>
      </li>
    );
  });

  const handleClick = () => {
    props.setClicked(!props.clicked);
  };

  return (
    <motion.nav>
      <div className="logo">
        {/* Vidhi's<font> Portfoliyo</font> */}
        <img src={logo} alt="logo" />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2 }}
        className="menu-icon"
        onClick={handleClick}
      >
        {!props.clicked ? <FaBars /> : <ImCross />}
      </motion.div>
      {isMobile ? (
        <>
          <motion.ul
            animate={props.clicked ? "open" : "closed"}
            variants={variants}
            className={"menu-list"}
            // className={props.clicked ? "menu-list" : "menu-list close"}
          >
            {menuList}
          </motion.ul>
        </>
      ) : (
        <>
          <motion.ul
            // animate={props.clicked ? "open" : "closed"}
            // variants={variants}
            className={"menu-list"}
            // className={props.clicked ? "menu-list" : "menu-list close"}
          >
            {menuList}
          </motion.ul>
        </>
      )}
    </motion.nav>
  );
};

export default Navbar;
