import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "./Themes";
import { Design, Develope } from "./AllSvgs";

import SocialIcons from "../subComponents/SocialIcons";

import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitlte";
import { pageAnimation } from "../animation";
import { motion } from "framer-motion";

const Box = styled(motion.div)`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Main1 = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  width: 30vw;
  max-height: 55vh;

  z-index: 3;
  line-height: 1.5;
  cursor: pointer;

  font-family: "Ubuntu Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
  }
  @media screen and (max-width: 800px) {
    line-height: 1.3;
    padding: 0.5rem;
    width: 60vw;
    margin: 4px;
    margin-top: 100px;
  }
`;
const Main2 = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  width: 30vw;
  max-height: 55vh;
  z-index: 3;
  line-height: 1.5;
  cursor: pointer;

  font-family: "Ubuntu Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
  }
  @media screen and (max-width: 800px) {
    line-height: 1.3;
    padding: 0.5rem;
    width: 60vw;
    margin: 4px;
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vw);

  ${Main1}:hover & {
    & > * {
      fill: ${(props) => props.theme.body};
    }
  }
  ${Main2}:hover & {
    & > * {
      fill: ${(props) => props.theme.body};
    }
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`;

const Description = styled.div`
  color: ${(props) => props.theme.text};
  font-size: calc(0.6em + 1vw);
  padding: 0.5rem 0;

  ${Main1}:hover & {
    color: ${(props) => props.theme.body};
  }
  ${Main2}:hover & {
    color: ${(props) => props.theme.body};
  }

  strong {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  ul,
  p {
    margin-left: 2rem;
  }
`;

const MySkillsPage = (props) => {
  useEffect(() => {
    if (window.innerWidth <= 800) {
      if (props.clicked === true) {
        props.setClicked(false);
      }
    }
  }, []);
  return (
    <ThemeProvider theme={lightTheme}>
      <Box variants={pageAnimation} initial="hidden" animate="show" exit="exit">
        <SocialIcons theme="light" />

        <ParticleComponent theme="light" />

        <Main1>
          <Title>
            <Design width={40} height={40} /> Designer
          </Title>
          <Description>
            I love to create design which speaks, Keep it clean, minimal and
            simple.
          </Description>
          <Description>
            <strong>I like to Design</strong>
            <ul>
              <li>Web Design</li>
              {/* <li>Mobile Apps</li> */}
            </ul>
          </Description>
          <Description>
            <strong>Tools</strong>
            <ul>
              <li>Figma</li>
              <li>Frammer</li>
              <li>Canva</li>
            </ul>
          </Description>
        </Main1>
        <Main2>
          <Title>
            <Develope width={40} height={40} /> Web Developer
          </Title>
          <Description>
            I value business or brand for which I'm creating, thus I enjoy
            bringing new ideas to life.
          </Description>
          <Description>
            <strong>Skills</strong>
            <p>
              Html, Css, Js, React, Sass, Bootstrap, Tailwind, Firebase, Sanity
              etc.
            </p>
          </Description>
          <Description>
            <strong>Tools</strong>
            <p>VScode, Github, Sanity etc.</p>
          </Description>
        </Main2>

        <BigTitle text="SKILLS" top="80%" right="30%" />
      </Box>
    </ThemeProvider>
  );
};

export default MySkillsPage;
