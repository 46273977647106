import React, { useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";
import { motion } from "framer-motion";
import SocialIcons from "../subComponents/SocialIcons";

import Card from "../subComponents/Card";
import { YinYang } from "./AllSvgs";
import BigTitlte from "../subComponents/BigTitlte";
import { client } from "../client";

import { pageAnimation } from "../animation";
const Box = styled(motion.div)`
  background-color: ${(props) => props.theme.body};

  height: 400vh;
  position: relative;
  display: flex;
  align-items: center;
`;
const Box2 = styled.div`
  width: 7%;
  background-color: black;
  height: 100%;
  z-index: 2;
  @media screen and (max-width: 800px) {
    width: 25%;
  }
`;

const Main = styled(motion.ul)`
  position: fixed;
  top: 12rem;
  left: calc(10rem + 15vw);
  height: 40vh;
  display: flex;
  color: white;
  z-index: 1;
  @media screen and (max-width: 800px) {
    top: 15rem;
  }
`;

const Rotate = styled.span`
  display: block;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 80px;
  height: 80px;
  z-index: 1;
`;

// Framer-motion Configuration
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      staggerChildren: 0.5,
      duration: 0.5,
    },
  },
};

const WorkPage = (props) => {
  useEffect(() => {
    if (window.innerWidth <= 800) {
      if (props.clicked === true) {
        props.setClicked(false);
      }
    }
  }, []);
  const ref = useRef(null);
  const yinyang = useRef(null);
  const [works, setWorks] = useState([]);

  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
    });
  }, []);

  useEffect(() => {
    let element = ref.current;

    const rotate = () => {
      element.style.transform = `translateX(${-window.pageYOffset}px)`;

      return (yinyang.current.style.transform =
        "rotate(" + -window.pageYOffset + "deg)");
    };

    window.addEventListener("scroll", rotate);
    return () => {
      window.removeEventListener("scroll", rotate);
    };
  }, []);

  return (
    <ThemeProvider theme={DarkTheme}>
      <Box variants={pageAnimation} initial="hidden" animate="show" exit="exit">
        <SocialIcons theme="dark" />
        <Box2 />
        <Main ref={ref} variants={container} initial="hidden" animate="show">
          {works.map((d, i) => (
            <Card key={i} data={d} id={i} />
          ))}
        </Main>
        <Rotate ref={yinyang}>
          <YinYang width={80} height={80} fill={DarkTheme.text} />
        </Rotate>

        <BigTitlte text="WORK" top="10%" right="20%" z="3" />
      </Box>
    </ThemeProvider>
  );
};

export default WorkPage;
