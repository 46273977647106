import React, { useEffect, useState } from "react";
import styled from "styled-components";
import img from "../assets/Images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg";
import SocialIcons from "../subComponents/SocialIcons";

import CertiComponent from "./CertiComponent";
import BigTitle from "../subComponents/BigTitlte";
import { motion } from "framer-motion";
import { client } from "../client";
import { pageAnimation } from "../animation";

const MainContainer = styled(motion.div)`
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
`;
const Container = styled(motion.div)`
  background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.8)`};
  width: 100%;
  height: auto;

  position: relative;
  padding-bottom: 5rem;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
`;

const Grid = styled.div`
  /* display: grid;
  grid-template-columns: repeat(2, minmax(calc(10rem + 15vw), 1fr));
  grid-gap: calc(1rem + 2vw); */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    margin: 10%;
  }
`;

// Framer-motion config
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      staggerChildren: 0.5,
      duration: 0.5,
    },
  },
};

const BlogPage = (props) => {
  useEffect(() => {
    if (window.innerWidth <= 800) {
      if (props.clicked === true) {
        props.setClicked(false);
      }
    }
  }, []);
  const [numbers, setNumbers] = useState(0);

  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    const query = '*[_type == "certificates"]';

    client.fetch(query).then((data) => {
      setCertificates(data);
    });
  }, []);

  useEffect(() => {
    let num = (window.innerHeight - 70) / 30;
    setNumbers(parseInt(num));
  }, []);

  return (
    <MainContainer
      variants={container}
      initial="hidden"
      animate="show"
      exit={{
        opacity: 0,
        transition: { duration: 0.5 },
      }}
    >
      <Container
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        <SocialIcons />
        <Center>
          <Grid>
            {certificates.map((certificate, i) => {
              return (
                <CertiComponent key={i} certificate={certificate} id={i} />
              );
            })}
          </Grid>
        </Center>
        <BigTitle text="Certificates" top="5rem" left="5rem" />
      </Container>
    </MainContainer>
  );
};

export default BlogPage;
