import { motion } from "framer-motion";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Facebook, Github, Twitter, YouTube } from "../components/AllSvgs";
import { DarkTheme } from "../components/Themes";

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 2rem;

  z-index: 3;

  & > *:not(:last-child) {
    margin: 0.5rem 0;
  }
`;

const Line = styled(motion.span)`
  width: 2px;
  height: 8rem;
  background-color: ${(props) =>
    props.color === "dark" ? DarkTheme.text : DarkTheme.body};
`;

const SocialIcons = (props) => {
  return (
    <Icons>
      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1 }}
      >
        <motion.div
          whileHover={{ scale: 1.4, originx: 0, color: "#f8e112" }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <NavLink
            style={{ color: "inherit" }}
            target="_blank"
            to={{ pathname: "https://github.com/vidhi-01/" }}
          >
            <Github
              width={25}
              height={25}
              fill={props.theme === "dark" ? DarkTheme.text : DarkTheme.body}
            />
          </NavLink>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1.2 }}
      >
        <motion.div
          whileHover={{ scale: 1.4, originx: 0, color: "#f8e112" }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <NavLink
            style={{ color: "inherit" }}
            target="_blank"
            to={{ pathname: "https://twitter.com/vidhi_1121" }}
          >
            <Twitter
              width={25}
              height={25}
              fill={props.theme === "dark" ? DarkTheme.text : DarkTheme.body}
            />
          </NavLink>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1.4 }}
      >
        <motion.div
          whileHover={{ scale: 1.4, originx: 0, color: "#f8e112" }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <NavLink
            style={{ color: "inherit" }}
            target="_blank"
            to={{
              pathname: "https://www.linkedin.com/in/vidhi-patel-9521ba229/",
            }}
          >
            <Facebook
              width={30}
              height={30}
              fill={props.theme === "dark" ? DarkTheme.text : DarkTheme.body}
            />
          </NavLink>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1.6 }}
      >
        <motion.div
          whileHover={{ scale: 1.4, originx: 0, color: "#f8e112" }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <NavLink
            style={{ color: "inherit" }}
            target="_blank"
            to={{
              pathname:
                "https://www.youtube.com/channel/UCOkON4_CcGWwIMZWLyUQAvQ",
            }}
          >
            <YouTube
              width={25}
              height={25}
              fill={props.theme === "dark" ? DarkTheme.text : DarkTheme.body}
            />
          </NavLink>
        </motion.div>
      </motion.div>

      <Line
        color={props.theme}
        initial={{
          height: 0,
        }}
        animate={{
          height: "8rem",
        }}
        transition={{
          type: "spring",
          duration: 1,
          delay: 0.8,
        }}
      />
    </Icons>
  );
};

export default SocialIcons;
