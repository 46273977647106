import React, { useState, useEffect } from "react";
import "./contact.css";
import AnimatedLetters from "../AnimatedLetters/AnimatedLetters";
import { client } from "../../client";
import { pageAnimation } from "../../animation";
import { motion } from "framer-motion";
import styled from "styled-components";

const ContacUs = (props) => {
  useEffect(() => {
    if (window.innerWidth <= 800) {
      if (props.clicked === true) {
        props.setClicked(false);
      }
    }
  }, []);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    number: "",
    email: "",
    message: "",
  });
  const [letterClass, setLetterClass] = useState("text-animate");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { firstname, lastname, mobile, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      firstName: formData.firstname,
      lastName: formData.lastname,
      number: formData.mobile,
      email: formData.email,
      message: formData.message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);
  }, []);

  return (
    <Hide
      className="container-main"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <section>
        <div className="container">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={[
                "G",
                "e",
                "t",
                " ",
                " ",
                "I",
                "n",
                " ",
                " ",
                "T",
                "o",
                "u",
                "c",
                "h",
              ]}
              idx={15}
            />
          </h1>
          {!isFormSubmitted ? (
            <>
              <div className="row100">
                <div className="col">
                  <div className="inputBox">
                    <input
                      required="required"
                      type="text"
                      name="firstname"
                      value={firstname}
                      onChange={handleChangeInput}
                    />
                    <span className="text">First Name</span>
                    <span className="line"></span>
                  </div>
                </div>
                <div className="col">
                  <div className="inputBox">
                    <input
                      type="text"
                      required="required"
                      name="lastname"
                      value={lastname}
                      onChange={handleChangeInput}
                    />
                    <span className="text">Last Name</span>
                    <span className="line"></span>
                  </div>
                </div>
              </div>
              <div className="row100">
                <div className="col">
                  <div className="inputBox">
                    <input
                      className="p-text"
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChangeInput}
                      required="required"
                    />
                    <span className="text">Email</span>
                    <span className="line"></span>
                  </div>
                </div>
                <div className="col">
                  <div className="inputBox">
                    <input
                      type="text"
                      name="mobile"
                      value={mobile}
                      onChange={handleChangeInput}
                      required="required"
                    />
                    <span className="text">Mobile</span>
                    <span className="line"></span>
                  </div>
                </div>
              </div>
              <div className="row100">
                <div className="col">
                  <div className="inputBox textarea">
                    <textarea
                      required="required"
                      className="p-text"
                      value={message}
                      name="message"
                      onChange={handleChangeInput}
                    ></textarea>
                    <span className="text">Type Your Message Here...</span>
                    <span className="line"></span>
                  </div>
                </div>
              </div>
              <div className="row100">
                <div className="col">
                  <button
                    type="button"
                    className="p-text"
                    onClick={handleSubmit}
                  >
                    {!loading ? "Send Message" : "Sending..."}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div>
              <h3 className="head-text">Thank you for getting in touch!</h3>
            </div>
          )}
        </div>
      </section>
    </Hide>
  );
};

const Hide = styled(motion.div)``;

export default ContacUs;
