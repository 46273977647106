import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Me1 from "../assets/Images/p1.png";
import Me2 from "../assets/Images/p2.png";
import TypeWriter from "../subComponents/TypeWriter";

const Box = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 65vw;
  height: 55vh;
  display: flex;

  background: linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      bottom,
    linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      top;
  background-size: 100% 2px;
  background-repeat: no-repeat;
  border-left: 2px solid ${(props) => props.theme.body};
  border-right: 2px solid ${(props) => props.theme.text};

  z-index: 1;
`;
const BoxMob = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 65vw;
  height: 55vh;
  display: flex;

  background-repeat: no-repeat;

  z-index: 1;
  flex-direction: column;
  background-size: 2px 100%;
  background: linear-gradient(to bottom, #fcf6f4 50%, black 50%) 0px 0px / 2px
      100% no-repeat,
    linear-gradient(to top, black 50%, #fcf6f4 50%) 100% 0px / 2px 100%
      no-repeat;
  border-top: 2px solid ${(props) => props.theme.body};
  border-bottom: 2px solid ${(props) => props.theme.text};
`;
const SubBox = styled.div`
  width: 50%;
  position: relative;
  display: flex;

  .pic {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: auto;
    height: 100%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 50%;
  }
`;

const Text = styled.div`
  font-size: calc(1em + 1.5vw);
  color: ${(props) => props.theme.body};
  padding: 2rem;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & > *:last-child {
    color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 300;
  }
`;

export const Intro1 = () => {
  const [pic, setPic] = useState(Me2);
  const chnagePic = () => {
    if (pic === Me1) {
      setPic(Me2);
    } else {
      setPic(Me1);
    }
  };
  return (
    <Box
      initial={{ height: 0 }}
      animate={{ height: "55vh" }}
      transition={{ type: "spring", duration: 2, delay: 1 }}
    >
      <SubBox>
        <Text>
          <h1>Hi,</h1>
          <TypeWriter first="I'm Vidhi" second="I'm Coder" />
          <h6>
            “ In order to be irreplaceable, one must always be different ”
          </h6>
        </Text>
      </SubBox>
      <SubBox>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2 }}
          onClick={() => chnagePic()}
        >
          <motion.img className="pic" src={pic} alt="Profile Pic" />
        </motion.div>
      </SubBox>
    </Box>
  );
};
export const Intro2 = () => {
  const [pic, setPic] = useState(Me2);
  const chnagePic = () => {
    if (pic === Me1) {
      setPic(Me2);
    } else {
      setPic(Me1);
    }
  };
  return (
    <BoxMob
      initial={{ height: 0 }}
      animate={{ height: "55vh" }}
      transition={{ type: "spring", duration: 2, delay: 1 }}
    >
      <SubBox>
        <Text>
          <h1>Hi,</h1>
          <TypeWriter first="I'm Vidhi" second="I'm Coder" />
          <h6>“In order to be irreplaceable, one must always be different”</h6>
        </Text>
      </SubBox>
      <SubBox>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2 }}
          onClick={() => chnagePic()}
        >
          <motion.img className="pic" src={pic} alt="Profile Pic" />
        </motion.div>
      </SubBox>
    </BoxMob>
  );
};
