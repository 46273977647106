import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: `9crfgv88`,
  dataset: "production",
  apiVersion: "2022-03-13",
  useCdn: true,
  token: `skDqtlOIqdbDr8BUdDvBhkM6sl0MtUbUF7HGVAr1RYCCRddj7oV7sZhVgMyqPm4FEhJiZQZzTAQLydAps8vKk6uk2679q7BYy0MLtWU0kL5Wx032WqXS9xjs8c5yKvoPG8Nz1SKsb3A0ZAUtntcYaY3Fy7YfvJ4TFYONgW3dTtBT3KyIsMFF`,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
