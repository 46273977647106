import { Route, Switch, useLocation } from "react-router";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./components/Themes";
import GlobalStyle from "./globalStyles";
import { useState } from "react";
//Components
import Main from "./components/Main";
// import NavBar from "./components/NavBar";
import NavBar from "./components/navbar/Navbar";

import AboutPage from "./components/AboutPage";
import CertiPage from "./components/CertiPage";
import WorkPage from "./components/WorkPage";
import MySkillsPage from "./components/MySkillsPage";
import ContactUs from "./components/contact/ContacUs";
import { AnimatePresence, FlatTree } from "framer-motion";

// import Art from "./components/Art";

function App() {
  const [clicked, setClicked] = useState(false);

  const location = useLocation();
  return (
    // <Art />
    <>
      <GlobalStyle />
      <NavBar clicked={clicked} setClicked={setClicked} />
      <ThemeProvider theme={lightTheme}>
        {/* <SoundBar /> */}

        {/* For framer-motion animation on page change! */}
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <Main clicked={clicked} setClicked={setClicked} />
            </Route>
            <Route exact path="/about">
              <AboutPage clicked={clicked} setClicked={setClicked} />
            </Route>
            <Route exact path="/certificates">
              <CertiPage clicked={clicked} setClicked={setClicked} />
            </Route>
            <Route exact path="/work">
              <WorkPage clicked={clicked} setClicked={setClicked} />
            </Route>
            <Route exact path="/skills">
              <MySkillsPage clicked={clicked} setClicked={setClicked} />
            </Route>
            <Route exact path="/contactus">
              <ContactUs clicked={clicked} setClicked={setClicked} />
            </Route>
          </Switch>
        </AnimatePresence>
      </ThemeProvider>
    </>
  );
}

export default App;
