import React from "react";

//importing typewriter-effect
import styled, { keyframes, ThemeProvider } from "styled-components";
import Typewriter from "typewriter-effect";

const Type = styled.div`
  /* font-family: "Akaya Telivigala", cursive; */
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`;

function TypeWriter(props) {
  return (
    <Type>
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .pauseFor(1000)
            .typeString(props.first)
            .pauseFor(1000)
            .deleteAll()
            .typeString(props.second)
            .start()
            .pauseFor(1000);
        }}
        options={{
          autoStart: true,
          loop: true,
        }}
      />
    </Type>
  );
}

export default TypeWriter;
